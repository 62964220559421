<template>
  <div>
    <el-form :model="invoice" ref="fUserForm" label-width="80px" class="demo-ruleForm" :size="small">
      <el-row>
        <el-col :span="8">
          <el-form-item label="发票代码">
            <el-input v-model="invoice.invCode"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="发票号码">
            <el-input v-model="invoice.invNumber"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-button type="primary" icon="el-icon-search" :size="small" @click="findByPage">搜索</el-button>
        <!--<el-button type="primary" :size="small">主要按钮</el-button>
        <el-button type="success" :size="small">成功按钮</el-button>
        <el-button type="info" :size="small">信息按钮</el-button>
        <el-button type="warning" :size="small">警告按钮</el-button>
        <el-button type="danger" :size="small">危险按钮</el-button>-->
      </el-row>
    </el-form>

    <el-table
        :data="tableData"
        :height="tableHeight"
        border
        :size="small"
        style="width: 100%;margin-top: 10px;"
        v-loading="loading"
        ref="topictable"
    >
      <el-table-column
          type="selection"
          width="55">
      </el-table-column>
      <el-table-column
          prop="id"
          label="id"
          width="120">
      </el-table-column>
      <el-table-column
          prop="createdTime"
          label="创建时间"
          show-overflow-tooltip>
      </el-table-column>
      <!--<el-table-column
          prop="invType"
          label="发票类型"
          show-overflow-tooltip>
      </el-table-column>-->
      <el-table-column
          prop="invCode"
          label="代码"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="invNumber"
          label="号码"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="invDate"
          label="日期"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="invAmount"
          label="金额"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="invTax"
          label="税额"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="invTotalAmount"
          label="价税合计"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="100">
        <!--        <template slot-scope="scope">-->
        <template slot-scope="{row}">
          <el-button @click="toInvoiceDetail(row)" type="text" :size="small">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageTotal"
        style="margin-top: 10px">
    </el-pagination>

    <!--<el-drawer
        title=""
        :size="`90%`"
        :destroy-on-close="true"
        :visible.sync="drawer.status"
        :with-header="false">
      <InvoiceDetail :id="drawer.id"/>
    </el-drawer>-->

    <el-dialog
        title="发票详情"
        :destroy-on-close="true"
        :visible.sync="drawer.status"
        v-if="drawer.status"
        top="5vh"
        width="70%">
        <div class="el-dialog-div">
          <InvoiceDetail :id="drawer.id" :findByPage="findByPage" :dialog="drawer" v-bind="$attrs"/>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";
import InvoiceDetail from "@/views/invoice/InvoiceDetail";

export default {
  name: "InvoiceTab",
  components: {
    InvoiceDetail
  },
  props: {
    type: {
      type: String,
      default: '1'
    },
  },
  data() {
    return {
      small: 'small',
      drawer: {
        status: false,
        id : undefined
      },
      tableData: [],
      multipleSelection: [],
      currentPage: 1,
      pageSize: 10,
      loading: true,
      pageTotal: 0,
      tableHeight: null,
      invoice: {
        invNumber: '',
        invCode: '',
        invType: ''
      },
    }
  },

  methods: {
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.findByPage()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.findByPage()
    },
    toInvoiceDetail(row){
      this.drawer.id = row.id
      this.drawer.status = true
    },
    onSubmit() {
      this.findByPage();
    },
    findByPage() {
      this.loading = true
      this.invoice.invType = this.type

      return request({
        url: `/invoice/edocInvoice/findByPage/${this.currentPage}/${this.pageSize}`,
        method: 'post',
        contentType: 'application/json',
        data: this.invoice,
        baseURL: 'http://127.0.0.1:8092'
      }).then((response) => {
        this.tableData = response.data.content;
        this.pageTotal = response.data.totalElements;
        this.loading = false;
      })
    }
  }, mounted() {
    this.findByPage()
    this.tableHeight =
        window.innerHeight - this.$refs.topictable.$el.offsetTop - 195;
  },
}
</script>

<style scoped>
.el-dialog-div{
  height: 60vh;
  overflow: auto;
}
</style>
