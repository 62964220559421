<template>
  <el-tabs v-model="activeName" >
    <el-tab-pane v-for="tab in tabList" :label="tab.value" :name="tab.key" :key="tab.key">
      <invoice-tab v-if="activeName==tab.key" :type="tab.key" :invoiceTypeList="tabList"></invoice-tab>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import request from '@/utils/request'
import InvoiceTab from "@/views/invoice/InvoiceTab";

export default {
  components: {
    InvoiceTab
  },
  data() {
    return {
      tabList: [],
      activeName: ''
    };
  },
  methods: {
  },
  created() {
    return request({
      url: `/invoice/config/getInvoiceType`,
      method: 'post',
      contentType: 'application/json',
      baseURL: 'http://127.0.0.1:8092'
    }).then((response) => {
      this.tabList = response.data
      this.activeName = response.data[0].key
    })
  }
};
</script>

<style scoped>
.el-tabs__header .el-tabs__item.is-active {
  color: #409EFF;
  background-color: #FFF;
  border-right-color: #DCDFE6;
  border-left-color: #DCDFE6;
}
</style>
